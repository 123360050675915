<template>
  <div class="home">
    <v-container fill-height>
      <v-row align="center">
        <v-col>
          <v-card>
            <v-card-title>
              Registration Landing
            </v-card-title>
            <v-card-text>
              {{ JSON.stringify(user) }}
            </v-card-text>
          </v-card>
        </v-col>
      </v-row>
    </v-container>
  </div>
</template>

<script>
  // import NotificationSignup from '@/components/NotificationSignup'
  import { mapState, mapActions } from 'vuex'

  export default {
    name: 'RegistrationLanding',
    components: {
      // NotificationSignup
    },
    computed: {
      ...mapState(['user'])
    },
    methods: {
      ...mapActions(['monitorAuthState'])
    },
    mounted() {
      this.monitorAuthState()
    }
  }
</script>

<style scoped>

</style>